body {
  cursor: default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media (min-width: 550px) {
  .interactive:hover {
    -webkit-filter: brightness(1.1);
    filter: brightness(1.1);
  }

  .interactive:active {
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8);
  }

  .interactiveLight:hover {
    -webkit-filter: brightness(0.9);
    filter: brightness(0.9);
  }

  .interactiveLight:active {
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8);
  }
}

.movable {
  transition: left 100ms ease-in 0ms;
}

.modalContainer {
  max-height: 90%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .desktopBoxShadow {
    box-shadow: rgba(0, 0, 0, 0.06) 0 6px 25px 0;
  }
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@keyframes animationSlideLeftAnimation {
  from {
    transform: translate(20px);
    opacity: 0;
  }
  to {
    transform: translate(0px);
    opacity: 1;
  }
}

@keyframes animationSlideRightAnimation {
  from {
    transform: translate(-20px);
    opacity: 0;
  }
  to {
    transform: translate(0px);
    opacity: 1;
  }
}

.animationSlideLeft {
  animation-name: animationSlideLeftAnimation;
  animation-duration: 100ms;
}

.animationSlideRight {
  animation: animationSlideRightAnimation 100ms ease-in 0;
}

.my-animated-screen-enter {
  transition: transform 300ms, opacity 300ms;
  opacity: 0;
}

.my-animated-screen-enter-active {
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.my-animated-screen-exit {
  /* transform: translate(0px); */
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.my-animated-screen-exit-active {
  /* transform: translate(-50px); */
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}

.fadeover-backwards-screen-enter {
  /* transform: translate(-50px); */
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}

.fadeover-backwards-enter-active {
  /* transform: translate(0px); */
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.fadeover-backwards-exit {
  /* transform: translate(0px); */
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.fadeover-backwards-exit-active {
  /* transform: translate(50px); */
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}

.interactiveFileUpload > div.deleteLabel {
  display: none;
}

.interactiveFileUpload:hover > div.deleteLabel {
  display: flex;
}

.modalInput::-webkit-input-placeholder {
  text-align: center;
}

.modalInput:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.modalInput::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.modalInput:-ms-input-placeholder {
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.shake-me {
  -webkit-animation: shake 0.5s linear;
  animation: shake 0.5s linear;
  -moz-animation: shake 0.5s linear;
}

@-webkit-keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes shake {
  8%,
  41% {
    transform: translateX(-10px);
  }
  25%,
  58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes shake {
  8%,
  41% {
    -moz-transform: translateX(-10px);
  }
  25%,
  58% {
    -moz-transform: translateX(10px);
  }
  75% {
    -moz-transform: translateX(-5px);
  }
  92% {
    -moz-transform: translateX(5px);
  }
  0%,
  100% {
    -moz-transform: translateX(0);
  }
}

.mthMasonryComponent {
  margin: 0 auto;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.destyledSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.listHeader {
  position: sticky;
  top: 0;
  background: #FFF;
  box-shadow: rgba(0, 0, 0, 0.06) 0 6px 25px 0;
  z-index: 5;
  margin-bottom: 2rem;
  min-height: 92px;
}
.listFooter {
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  padding: .5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.06) 0 -6px 2px 0;
}

.listViewContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.listViewItemDefault {
  margin-bottom: 1%; /* (100-32*3)/2 */
  width: 10.93rem;
  height: 14.6rem;
}
.listViewItem{
  flex: 0 0 auto;
  min-height: 10rem;
}

.showFavoritedOnHover:hover > .favoritedButton {
  display: flex;
}

button:focus {
  outline: none;
}

.showFavoritedOnHover:hover > .hoverFooterButton {
  display: flex;
}


@media (min-width: 1024px) {
  .lg-grid-cols-1, .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg-grid-cols-2, .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg-grid-cols-3, .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg-grid-cols-4, .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xxl-grid-cols-2, .xxl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xxl-grid-cols-3, .xxl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xxl-grid-cols-4, .xxl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xxl-grid-cols-6, .xxl\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xxl-grid-cols-8, .xxl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 1680px) {
  .max-grid-cols-2, .max\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .max-grid-cols-3, .max\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .max-grid-cols-4, .max\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .max-grid-cols-6, .max\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .max-grid-cols-8, .max\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}


.tableview {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
}
.tableview tbody tr:nth-child(odd) {
  background-color: #e9e9e9;
}
.tableview tbody tr {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.tableview thead th {
  vertical-align: top;
}

.tableview img {
  max-width: 200px;
  width: 130px;
  cursor: pointer;
  display: block;
}
.tableview tbody td {
  vertical-align: top;
  position: relative;
  cursor: pointer;
}

.listView {
  border-right: 2px solid #d2d2d2;
}

@media print {
  header,
  .singleView,
  .listFooter button,
  .btn {
    display: none;
  }

  * {
    font-size: 10pt !important;
    color: #333333 !important;
  }

  .overflow-hidden {
    overflow: visible;
  }

  .listView {
    margin-top: 100px;
    width: 100%;
    overflow: visible;
    height: auto;
    border-right: none;
 }

  .listViewItem {
    width: 15%;
    margin: 10pt 1.5%;

  }
  .listViewItem .absolute button {
    display: none;
  }
  .listFooter {
    position: absolute;
    top: 0;
    bottom: auto;
    height: 80px;
    box-shadow: none;
  }

  .tableview {
    width: auto;
    table-layout: unset;
  }

  .tableview thead th {
    width: auto !important;
  }
  .tableview tbody td img  {
    height: 2cm !important;
    width: auto !important;
  }

  .desktopBoxShadow {
    box-shadow: none;
  }
}


button#ViewQuestions {
  top: 0 !important;
}

.spinner {
  width: 30px;
  height: 30px;
  position: relative;
  margin: 20px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #C1002B;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}

button:disabled {
  cursor: not-allowed;
}

.tooltip-button .tooltip {
  display: none;
}
.tooltip-button:hover .tooltip {
  display: block;
  top: 100%;
  width: 250px;
  transform: translateX(-25%);
}

.personCard img{
  height: 100%;
  width: 100%;
}

.previewButton .preview2{
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.previewButton:hover .preview2{
  opacity: 1;
}